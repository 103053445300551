import { useEffect} from 'react';

const DebounceInput = (props: any) => {
  useEffect(() => {
    let delayDebounceFn = setTimeout(() => {
      props.init(true)
      props.text.trim().length >= 3 ? props.process() : props.failed();
    }, 250);
    return () => clearTimeout(delayDebounceFn);
  }, [props.text]);
  return props.input
}
export default DebounceInput;
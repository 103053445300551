import { FetchLocation, SearchLocation } from '../../api/home';
import React, { Fragment, useState} from 'react';
import styles from '../../styles/modules/ui-components/google.module.scss';
import Image from '../common/Image'
import CITIES_SLUG from '../../city_constants';
import DebounceInput from '../common/ui-components/input/DebounceInput'
import { manualLocationSearchEmpty } from '../../tracking/segment/homePage';
import { getSelectedCityFromCookie } from '../../utils/cities';
import store from '../../redux';
import { setCityChangePopUpAction } from '../../redux/actions/genericActions';
import {connect} from 'react-redux';
import { isAmcInstaCart } from '../../utils/cart';
import { getFilteredPredictionList, handleSelectedPrediction } from '../../utils';
import { useDispatch } from "react-redux";
import { cleverTapTrigger } from '../../tracking/segment';

const GoogleAutoComplete = (props) => {
    const [location, setLocation] = useState<string>("");
    const [predictions, setPredictions] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [info, setInfo] = useState<string>("")
    const [track,setTrack] = useState<boolean>(true)
    const dispatch:any = useDispatch();

    const debounceInit = () => {
        setLoading(true)
    }
    const debounceProcess = () => {
        setInfo("")
        let text = location.trim()
        text = (props.city && props.pincode && text !== props.pincode) ? text+" "+props.city+" "+props.pincode : text
        SearchLocation({text: text, source: props.isTrackerAddress ? "Verify Address on SR Creation" : "home page"}).then( async (res)=> {
            props.isTrackerAddress && cleverTapTrigger("Location Searched", {})
            let prediction_list;
            
            if(res?.data?.city){

                prediction_list = !props.isTrackerAddress ||  props.city === res.data.city 
                            ? [{'description':`${res.data.city}, ${res.data.pincode} ${res.data.state} `,pincode:true,...res.data}] 
                            : []
            }
            else{
                prediction_list = res?.data.info ? [] : props.isTrackerAddress ? await getFilteredPredictionList(res?.data, props.city) : res?.data
            }
            setPredictions(prediction_list)
            setLoading(false)
            if(prediction_list.length==0){
                if(track){
                    setTrack(false)
                    manualLocationSearchEmpty({'search_string':location.trim()}) // Segment Event
                    setTimeout(()=>{
                        setTrack(true)
                    },3500)
                }
            } 
        })
    }
    const debounceFailed = () => {
        setPredictions([])
        setLoading(false)
        !props.isTrackerAddress && setInfo("Please enter 3 or more letters")
    }

    const handleSelection =  async (prediction) => {
        if(props.isTrackerAddress){
            let handleAddress:any = await handleSelectedPrediction(prediction, props.pincode, props.city, props.state)
            props.handleSelectedPrediction(handleAddress)
            return 
        }
        if(prediction.pincode){
            triggerCityChange(prediction.city)
        }
        else{
        setLoading(true)
        FetchLocation({place_id: prediction.place_id})
        .then((res)=>{
            let city:string = res?.data?.city || prediction.structured_formatting?.main_text || location
            triggerCityChange(city)
        })
        .catch(err=>{
            console.log(err)
            triggerCityChange(prediction.structured_formatting?.main_text || location)
        })
    }
    }
    
    const triggerCityChange = (city:string) => {
        let parsedCity = CITIES_SLUG[city.toUpperCase()] ? CITIES_SLUG[city.toUpperCase()]["city"] : city.charAt(0) + city.slice(1).toLowerCase()
        let cookie_city = getSelectedCityFromCookie()
        if (parsedCity != cookie_city && isAmcInstaCart(props.cart) && props.cart?.quantity){
            store.dispatch(setCityChangePopUpAction({state:true,city_data:{name:parsedCity}}))
        }else{
            props.onChange(parsedCity)
            setLoading(false)
        }
    }
    console.log(location)
        return (
            <Fragment>
                <div className={styles.inputSearch}>
                <DebounceInput text={location} input={
                    <input maxLength={50} autoComplete='off' role="textbox" className={`${styles.location_search} ${props.isTrackerAddress && styles.tracker_search}`} onChange={(e) => setLocation(e.target.value)} autoFocus value={location} placeholder={props.placeholder || "Search locality, area, pincode"} />
                } init={debounceInit} process={debounceProcess} failed={debounceFailed}/> 
                {props?.isTrackerAddress && location.length >0 && <div className={styles.removeInput} onClick={() =>{setLocation("")}}></div>}
                </div>
                {props.isTrackerAddress && <div className={styles.detect_location}>
                        <div>
                            <Image width="20" height="20" src={"/static/images/home/geo_icon.png"} alt="detectLocation"/>
                        </div>
                        <p onClick={props.detectLocation}>Detect My Location</p>
                    </div>
                }
                {predictions.length!==0 && location.length > 2 &&  predictions.map((prediction)=> {return <div className={`${styles.location} ${props.isTrackerAddress && styles.tracker_location}`} onClick={(e) => {
                 handleSelection(prediction)}}>
                     <div className={styles.location_img}>
                        <Image width="25" height="25" src={"/static/images/home/location_icon.png"}/>
                     </div>
                     <div className={styles.location_text}>
                         <p className={styles.small_add} >{prediction.description.split(",")[0]}</p>
                        <p className={styles.long_add} >{prediction.description.replace(prediction.description.split(",")[0]+",","")}</p>
                     </div>
                    </div>})}
                {
                    info ?
                    (<div className={styles.hint_div}><i className={styles.hint_icon}>i</i><p className={styles.hint_text}>{info}</p></div>) :
                    (predictions.length===0 && location.length > 2 && !loading && <><p className={styles.not_found}>No results found for your search</p><p className={styles.another_loc}>Search another location</p></>)
                }
            </Fragment>
        );

}

const mapStateToProps = (state:any) => {
    return {
      cart: state.cart,
    }
  }

export default connect(mapStateToProps,null)(GoogleAutoComplete);

import axios from 'axios';
import Settings from '../../config';

const GOOGLE_MAPS_BASE = 'https://maps.googleapis.com/maps/api';
const PLACES_BASE_URL = `${GOOGLE_MAPS_BASE}/place`;
const GOOGLE_MAP_KEY = ''


export const fetchPlaceDetail = async place_id => {
  const requiredFields = ['address_components'];
  if (!GOOGLE_MAP_KEY && Settings.DEBUG) {
    return {
      data: {},
      status: 'Location Fetch Failed GOOGLE MAPS API KEY MISSING',
    };
  }
  let params = {
    key: GOOGLE_MAP_KEY,
    place_id: place_id,
    fields: requiredFields.join(','),
  };
  const apiUrl = buildUrl(`${PLACES_BASE_URL}/details/json`, params);
  try {
    const response = await axios({method: 'get', url: apiUrl});
    const {data} = response;
    if (data && data.status === 'OK') {
      return data.result.address_components;
    }
  } catch (e) {
    console.log(e)
  }
  return false;
};

export const fetchPostalCodeByPlaceId = async place_id => {
  let address_components = await fetchPlaceDetail(place_id);
  let postalCodeComp = (address_components || []).filter(comp =>
    comp.types.includes('postal_code'),
  );
  if (postalCodeComp.length) {
    return postalCodeComp[0].long_name;
  }
  return null;
};

export const getGeoPos = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async position => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        resolve({lat, long});
      },
      error => {
        reject(error);
      }
    );
  });
};

  
  const buildUrl = (url, parameters) => {
    let qs = '';
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
      }
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1); //chop off last "&"
      url = url + '?' + qs;
    }
  
    return url;
  };

